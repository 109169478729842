// Padding.js
import React from 'react';
import PropTypes from 'prop-types';

const Padding = ({ top = 0, bottom = 0, children }) => {
    return (
        <div style={{ paddingTop: `${top}vh`, paddingBottom: `${bottom}vh` }}>
            {children}
        </div>
    );
};

Padding.propTypes = {
    top: PropTypes.number,
    bottom: PropTypes.number,
    children: PropTypes.node.isRequired,
};

export default Padding;
