import React from 'react';
import './Footer.css';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

const Footer = () => {
  const { t } = useTranslation();
  return (

  <footer className="footer">
    <p>{t("footer.title")}</p>
    <p>{t("footer.copiraite")}</p>
    <Link to="/privacy-policy">Политика конфиденциальности</Link>
    
  </footer>);
};

export default Footer;