import React, { useEffect, useState } from 'react';
import './Carousel.css';

// Импортируем изображения один раз вне компонента
const importedImages = require
  .context('../img/technologies', false, /\.(png|jpe?g|gif|svg)$/)
  .keys()
  .map((path) => require(`../img/technologies/${path.replace('./', '')}`));

const TechnologiesCarousel = () => {
  const [positions, setPositions] = useState([]);
  const imageWidth = 150; // Ширина изображения
  const gap = 50; // Интервал между изображениями
  const totalWidth = imageWidth + gap;

  useEffect(() => {
    // Устанавливаем начальные позиции
    const initialPositions = importedImages.map((_, index) => {
      return window.innerWidth + index * totalWidth;
    });
    setPositions(initialPositions);

    const interval = setInterval(() => {
      setPositions((prevPositions) => {
        const maxPosition = Math.max(...prevPositions);
        return prevPositions.map((pos) => {
          const newPos = pos - 1; // Двигаем изображения влево
          if (newPos < -imageWidth) {
            // Если изображение ушло за левый край, мгновенно перемещаем за правый край
            return maxPosition + totalWidth;
          }
          return newPos;
        });
      });
    }, 16); // Интервал обновления (примерно 60 FPS)

    return () => clearInterval(interval);
  }, [totalWidth]); // Убираем зависимость от `importedImages`

  return (
    <div className="carousel-container">
      <div className="carousel-track">
        {importedImages.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Technology ${index + 1}`}
            className="carousel-image"
            style={{ transform: `translateX(${positions[index] || 0}px)` }}
          />
        ))}
      </div>
    </div>
  );
};

export default TechnologiesCarousel;
