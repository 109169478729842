import React, { useState } from "react";
import "./DropdownMenu.css";
import { useTranslation } from "react-i18next";

const DropdownMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { i18n } = useTranslation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Смена языка
    setIsMenuOpen(false); // Закрыть меню после выбора
  };

  return (
    <div className="dropdown">
      <button className="menu-button" onClick={toggleMenu}>
        {i18n.language.toUpperCase()} {/* Отображение текущего языка */}
      </button>
      {isMenuOpen && (
        <div className="menu">
          <button className="menu-item" onClick={() => changeLanguage("en")}>
            English
          </button>
          <button className="menu-item" onClick={() => changeLanguage("ru")}>
            Русский
          </button>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
