// ThreeButtonsModule.js
import React from 'react';
import './ThreeButtonsModule.css';
import tel from '../img/3but/tel.png';
import web from '../img/3but/web.png';
import giga from '../img/3but/giga.png';
import { useTranslation } from "react-i18next";

const ThreeButtonsModule = () => {
    const { t } = useTranslation();

    const buttons = [
        { 
            title: t('threebuttons.webapp.title'), 
            text: t('threebuttons.webapp.text'), 
            image: web 
        },
        { 
            title: t('threebuttons.aigpt.title'), 
            text: t('threebuttons.aigpt.text'), 
            image: giga 
        },
        { 
            title: t('threebuttons.telegram.title'), 
            text: t('threebuttons.telegram.text'), 
            image: tel 
        },
    ];

    return (
        <div className="buttons-container">
            {buttons.map((button, index) => (
                <div key={index} className="button-wrapper">
                    <div className="button-content">
                        <div className="button-text">
                            <h2>{button.title}</h2>
                            <p>{button.text}</p>
                        </div>
                        <div className="button-image">
                            <img src={button.image} alt={button.title} />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ThreeButtonsModule;

