import React from "react";
import "./MainScreen.css";
import mainImg from "../img/mainimg.png";
import { useTranslation } from "react-i18next";

const MainScreen = ({ onCardClick }) => {
  const { t } = useTranslation(); // Подключаем i18n

  return (
    <div className="main-screen">
      <div className="image-header"></div>
      <div className="text-container">
        <p className="subtitle">{t("mainscreen.subtitle")}</p>
        <h1 className="title">{t("mainscreen.title")}</h1>
        <p className="description">{t("mainscreen.description")}</p>
      </div>
      <div className="image-container">
        <div className="gradient-border">
          <img src={mainImg} alt="Main Content" className="main-image" />
        </div>
      </div>
      <div className="button-container">
        <button className="action-button" onClick={() => onCardClick()}>
          {t("mainscreen.button")}
        </button>
      </div>
    </div>
  );
};

export default MainScreen;
