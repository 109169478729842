import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Импортируйте необходимые компоненты
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import CardBlock from './components/CardBlock.js';
import MainScreen from './components/MainScreen.js';
import TechnologiesCarousel from './components/TechnologiesCarousel';
import ThreeButtonsModule from './components/ThreeButtonsModule.js';
import Padding from './components/Padding.js';
import ContactsModule from './components/ContactsModule.js';
import Popup from './components/Popup.js'; // Импорт Popup
import PrivacyPolicy from './components/PrivacyPolicy.js'; // Импорт страницы политики конфиденциальности
import './App.css';
import './i18n.js'; // Импорт конфигурации
import { useTranslation } from 'react-i18next';

const App = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupContent, setPopupContent] = useState(null);

  const openPopup = (content) => {
    setPopupContent(content);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setPopupContent(null);
  };

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Смена языка
  };

  return (
    <div className="app">
      <Router> {/* Оборачиваем все в Router */}
        <Header />
        <main className="main-content">
          <Routes>
            {/* Основная страница */}
            <Route path="/" element={<MainScreen onCardClick={() => openPopup(<ContactsModule />)} />} />
            {/* Страница с политикой конфиденциальности */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>

          <Padding top={5} bottom={0} />
          <ThreeButtonsModule />
          <Padding top={5} bottom={0} />
          <CardBlock onCardClick={() => openPopup(<ContactsModule />)} />
          <Padding top={5} bottom={0} />
          <TechnologiesCarousel />
          <ContactsModule />
        </main>
        <Footer />
        {isPopupOpen && (
          <Popup isOpen={isPopupOpen} onClose={closePopup}>
            {popupContent}
          </Popup>
        )}
      </Router> {/* Закрываем Router */}
    </div>
  );
};

export default App;
