// src/components/PrivacyPolicy.js
import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
    <div style={{margin: "60px"}}>
      <h1>1. General Information:</h1>
    <p>Applications published on Google Play under the authorship of Kolesnikov Vladislav Olegovich, IP (hereinafter referred to as the Author).</p>

    <h2>Application List:</h2>
    <p>Speedomeret GPS</p>
    <p>The text of this Privacy Policy for applications on Google Play (hereinafter referred to as the Policy) is posted and permanently available on the website 000000dev.ru.</p>

    <h1>2. Terms of Use:</h1>
    <p>By downloading and installing the applications from the list above, you fully accept the terms of this Policy. If you do not agree with this Policy, please refrain from downloading these applications.</p>
    <p>The Author reserves the right to modify and/or supplement this Policy at any time without prior notice to users. You should review this Policy once a month for any changes or additions. If you continue to use the application, you confirm your agreement with the updated Policy.</p>

    <h1>3. What User Data is Collected and/or Processed by the Author:</h1>
    <p>In the applications published on Google Play from the list above, the following data may be collected and processed:</p>
    <ul>
        <li>User ID</li>
        <li>Device data</li>
        <li>Geolocation data (if permission is granted)</li>
        <li>Advertising data</li>
        <li>Payment data (if the app requires payment, openly specified)</li>
    </ul>
    <p>This data is anonymized and securely encrypted. It is necessary for debugging, app development, and operation, as well as for the functioning of advertising networks.</p>

    <h1>4. Contact Information:</h1>
    <p>If you have any questions regarding this Policy, you can contact us by email: <a href="mailto:help@000000dev.ru">help@000000dev.ru</a>.</p>

    <p>Last modified: December 26, 2024.</p>
<br></br>
<br></br>
<br></br>

    <h1>1. Общие сведения:</h1>
    <p>Приложения, опубликованные в Google Play под авторством Kolesnikov Vladislav Olegovich, IP (далее — Автор).</p>

    <h2>Список приложений:</h2>
    <p>Спидометр GPS-ГЛОНАСС</p>
    <p>Текст настоящей Политики конфиденциальности для приложений в Google Play (далее — Политика) размещен и постоянно доступен на сайте 000000dev.ru.</p>

    <h1>2. Условия использования:</h1>
    <p>При скачивании и установке приложений из списка выше, Вы в полном объеме принимаете условия настоящей Политики. Если Вы не согласны с настоящей Политикой, пожалуйста, откажитесь от загрузки данных приложений.</p>
    <p>Автор вправе время от времени изменять и/или дополнять настоящую Политику без предварительного письменного уведомления пользователей. Вам необходимо с периодичностью раз в месяц знакомиться с настоящей Политикой на предмет внесенных изменений и/или дополнений. Если Вы продолжаете пользоваться приложением, Вы подтверждаете свое согласие с новой редакцией Политики.</p>

    <h1>3. Какие данные пользователей собираются и/или обрабатываются Автором:</h1>
    <p>В приложениях, опубликованных в Google Play из списка выше, могут собираться и обрабатываться следующие данные:</p>
    <ul>
        <li>Идентификатор пользователя</li>
        <li>Данные устройства</li>
        <li>Геолокационные данные (если предоставлено разрешение)</li>
        <li>Рекламные данные</li>
        <li>Платежные данные (если в приложении требуется оплата, открыто указано)</li>
    </ul>
    <p>Эти данные обезличены и надежно шифруются. Они необходимы для отладки, разработки и работы приложения, а также для работы рекламных сетей.</p>

    <h1>4. Контакты:</h1>
    <p>При возникновении вопросов касательно настоящей Политики, Вы можете обратиться по электронной почте: <a href="mailto:help@000000dev.ru">help@000000dev.ru</a>.</p>

    <p>Последнее изменение: 26 декабря 2024 года.</p>
</div> 
    </div>
  );
};

export default PrivacyPolicy;
