import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend'; // Используем HTTP backend вместо FsBackend

i18n
  .use(HttpBackend) // Загрузка переводов через HTTP
  .use(LanguageDetector) // Определяем язык браузера
  .use(initReactI18next) // Интеграция с React
  .init({
    supportedLngs: ['en', 'ru'], // Языки, которые поддерживаются
    fallbackLng: 'en', // Язык по умолчанию
    detection: {
      order: ['cookie', 'localStorage', 'navigator'], // Определяем язык через эти источники
      caches: ['cookie', 'localStorage'], // Кэши для сохранения информации о языке
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json', // Путь к файлам перевода
    },
    interpolation: {
      escapeValue: false, // Не экранировать символы
    },
  });

export default i18n;
